<!-- vertical-layout -->
<ng-container *ngIf="coreConfig.layout.type === 'vertical'">
    <vertical-layout></vertical-layout>
</ng-container>
<!-- / vertical-layout -->
<!-- horizontal-layout -->
<ng-container *ngIf="coreConfig.layout.type === 'horizontal'">
    <horizontal-layout></horizontal-layout>
</ng-container>
<!-- / horizontal-layout -->
<!-- theme customizer -->
<!--<core-sidebar
  name="themeCustomizer"
  class="customizer d-none d-md-block"
  [invisibleOverlay]="true"
  *ngIf="coreConfig.layout.customizer"
>
  <a
    class="customizer-toggle d-flex align-items-center justify-content-center"
    (click)="toggleSidebar('themeCustomizer')"
  >
    <span [data-feather]="'settings'" [class]="'spinner'"></span>
  </a>
  <core-theme-customizer></core-theme-customizer>
</core-sidebar>-->
<!-- / theme customizer -->



<div bsModal #childModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="dialog-child-name" #modalVC>
    <div class="modal-dialog modal-md">
        <div class="modal-content">
            <div class="modal-header">
                <h5 id="dialog-child-name" class="modal-title pull-left">{{ 'OturumUzatmaMesajUst' | translate }}</h5>
                <button type="button" class="close pull-right" aria-label="Close" (click)="hideChildModal()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <h5 id="dialog-child-name" class="modal-title pull-left">{{ 'OturumUzatmaMesajAlt' | translate }}</h5>

                {{idleState}}

                <div class="row mrgbtm" style="padding-top:20px;">
                    <div class="col-md-6">
                        <button type="button" (click)="logout()" class="btn btn-danger" rippleEffect>{{ 'OturumCikis' | translate }}</button>
                    </div>
                    <div class="col-md-6">
                        <button type="button" (click)="stay()" class="btn btn-success" rippleEffect>{{ 'OturumDevam' | translate }}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
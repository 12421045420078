import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppDataService } from 'app/util/appdataservice';
import 'hammerjs';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { ToastrModule } from 'ngx-toastr'; // For auth after login toast

import { CoreModule } from '@core/core.module';
import { CoreCommonModule } from '@core/common.module';
import { CoreSidebarModule, CoreThemeCustomizerModule } from '@core/components';

import { coreConfig } from 'app/app-config';

import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';
import { SampleModule } from 'app/main/sample/sample.module';
import { AuthGuard, ErrorInterceptor, JwtInterceptor } from './auth/helpers';
import { NgxPermissionsModule } from 'ngx-permissions';
import { NgxYoutubePlayerModule } from 'ngx-youtube-player';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive'; // this includes the core NgIdleModule but includes keepalive providers for easy wireup
import { MomentModule } from 'angular2-moment'; // optional, provides moment-style pipes for date formatting
import { ModalModule } from 'ngx-bootstrap/modal';

const appRoutes: Routes = [
  {
    path: 'pages',
        loadChildren: () => import('./main/pages/pages.module').then(m => m.PagesModule)
      /*  , canActivate: [AuthGuard]*/
         
       ,runGuardsAndResolvers: 'always',

  },
  {
      path: 'authentication',
      loadChildren: () => import('./main/authentication/authentication.module').then(m => m.AuthenticationModule)
  },
  {
    path: '',
      redirectTo: '/authentication/authentication/login',
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: '/pages/miscellaneous/error' //Error 404 - Page not found
  }
];

@NgModule({
  declarations: [AppComponent],
    imports: [
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NgxPermissionsModule.forRoot(),
        
    RouterModule.forRoot(appRoutes, {
      scrollPositionRestoration: 'enabled', // Add options right here
        relativeLinkResolution: 'legacy',
        useHash: true,
        enableTracing: false,
        onSameUrlNavigation: 'reload'
    }),
    TranslateModule.forRoot(),

    //NgBootstrap
    NgbModule,
    ToastrModule.forRoot(),
    NgxYoutubePlayerModule.forRoot(),
    // Core modules
    CoreModule.forRoot(coreConfig),
    CoreCommonModule,
    CoreSidebarModule,
    CoreThemeCustomizerModule,

    // App modules
    LayoutModule,
    SampleModule,
    ModalModule.forRoot(),
    NgIdleKeepaliveModule.forRoot(),
    MomentModule
    ],
    providers:
    [
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        AppDataService
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}

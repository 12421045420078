import { CoreMenu } from '@core/types';
 

export const menu: CoreMenu[] = [
    // Apps & Pages
    {
        id: 'apps',
        type: 'section',
        title: 'Uyum 7/24',
        translate: 'MENU.APPS.SECTION',
        icon: 'package',
        children: [
            {
                id: 'Dashboard',
                title: 'Panel',
                translate: 'MENU.APPS.DASHBOARD',
                type: 'item',
                icon: 'home',
                url: 'pages/home/home-dashboard'
            },  
            {
                id: 'organizasyon',
                title: 'Organizasyon',
                translate: 'MENU.APPS.ORGANIZASYON.COLLAPSIBLE',
                type: 'collapsible',
                icon: 'briefcase',
                children: [
                    {
                        id: 'list',
                        title: 'Listele',
                        translate: 'MENU.APPS.ORGANIZASYON.LIST',
                        type: 'item',
                        icon: 'circle',
                        url: 'pages/organizasyon/organizasyon-liste'
                    },
                    //{
                    //    id: 'organizasyonSifre',
                    //    title: 'Listele',
                    //    translate: 'MENU.APPS.ORGANIZASYON.FIRMAIZINLERI',
                    //    type: 'item',
                    //    icon: 'circle',
                    //    url: 'pages/organizasyon/organizasyon-sifre'
                    //},
                    {
                        id: 'edit',
                        title: 'Yeni Organizasyon',
                        translate: 'MENU.APPS.ORGANIZASYON.NEW',
                        type: 'item',
                        icon: 'circle',
                        url: 'pages/organizasyon/organizasyon-form'
                    }
                ]
            },
            {
                id: 'musteri',
                title: 'Musteri',
                translate: 'MENU.APPS.MUSTERI.COLLAPSIBLE',
                type: 'collapsible',
                icon: 'briefcase',
                children: [
                    {
                        id: 'list',
                        title: 'Listele',
                        translate: 'MENU.APPS.MUSTERI.LIST',
                        type: 'item',
                        icon: 'circle',
                        url: 'pages/musteri/musteri-liste'
                    },
                    {
                        id: 'edit',
                        title: 'Yeni Müşteri',
                        translate: 'MENU.APPS.MUSTERI.NEW',
                        type: 'item',
                        icon: 'circle',
                        url: 'pages/musteri/musteri-form'
                    }
                ]
            },
            //{
            //    id: 'sablon',
            //    title: 'Envanter Şablon',
            //    translate: 'MENU.APPS.SABLON.COLLAPSIBLE',
            //    type: 'collapsible',
            //    icon: 'layers',
            //    role: ['Yonetici'],
            //    children: [
            //        {
            //            id: 'sablonlistele',
            //            title: 'Şablon Listele',
            //            translate: 'MENU.APPS.SABLON.LIST',
            //            type: 'item',
            //            icon: 'circle',
            //            url: 'pages/envanter/envanter-sablon-liste/true'
            //        },
            //        {
            //            id: 'sablonkaydet',
            //            title: ' Şablon Kaydet',
            //            translate: 'MENU.APPS.SABLON.NEW',
            //            type: 'item',
            //            icon: 'circle',
            //            url: 'pages/envanter/envanter-sablon/true'
            //        },
            //    ]
            //},
            //{
            //    id: 'dokuman',
            //    title: 'Doküman Set',
            //    translate: 'MENU.APPS.DOKUMANSET.DOKUMANSET',
            //    type: 'collapsible',
            //    icon: 'archive',
            //    role: ['Yonetici'],
            //    children: [
            //        {
            //            id: 'list',
            //            title: 'Doküman Set Listele',
            //            translate: 'MENU.APPS.DOKUMANSET.LIST',
            //            type: 'item',
            //            icon: 'circle',
            //            url: 'pages/dokuman/dokuman-set-liste'
            //        },    
            //    ]
            //},
            //{
            //    id: 'rapor',
            //    title: 'Raporlar',
            //    translate: 'MENU.APPS.RAPOR.LIST',
            //    type: 'collapsible',
            //    icon: 'layout',
            //    children: [
            //        {
            //            id: 'list',
            //            title: 'Raporlar',
            //            translate: 'MENU.APPS.RAPOR.LIST',
            //            type: 'item',
            //            icon: 'circle',
            //            url: 'pages/rapor/rapor-form'
            //        }
                    

            //    ]
            //},
            {
                id: 'tanimlar',
                title: 'Tanımlar',
                translate: 'MENU.APPS.TANIM.TANIMLAR',
                type: 'collapsible',
                icon: 'tool',
                children: [
                    {
                        id: 'organizasyontanim',
                        title: 'Organizasyon',
                        translate: 'MENU.APPS.TANIM.ORGANIZASYON',
                        type: 'collapsible',
                        icon: 'briefcase',
                        children: [
                            {
                                id: 'ulke',
                                title: 'Ülke',
                                translate: 'Ülke Listesi',
                                type: 'item',
                                icon: 'circle',
                                url: 'pages/tanim/ulke-liste/11D6D821-A12A-438D-8FB3-62537A59D8A9'
                            },
                            {
                                id: 'teefonTipleri',
                                title: 'Telefon Tipleri',
                                translate: 'Telefon Tipleri',
                                type: 'item',
                                icon: 'circle',
                                role: ['Yonetici'],
                                url: 'pages/tanim/telefon-tip-liste/165F0AFD-3E08-4FBC-96AB-643C455B8E2B'
                            },
                            {
                                id: 'sektor',
                                title: 'Sektörler',
                                translate: 'Sektörler',
                                type: 'item',
                                icon: 'circle',
                                url: 'pages/tanim/sektor-liste/8418E39B-4285-4163-88B6-561FA98DB8E4'
                            },
                            {
                                id: 'surecler',
                                title: 'Süreçler',
                                translate: 'Süreçler',
                                type: 'item',
                                icon: 'circle',
                                url: 'pages/tanim/surec-liste/1B380D57-A8A0-41FD-A2E6-C08278078FEA'
                            },
                            


                        ]
                    },
                    //{
                    //    id: 'uyumluluktanim',
                    //    title: 'Uyumluluk',
                    //    translate: 'MENU.APPS.TANIM.UYUMLULUK',
                    //    type: 'collapsible',
                    //    icon: 'eye',
                    //    role: ['Yonetici'],
                    //    children: [
                    //        {
                    //            id: 'uyumluluktanim',
                    //            title: 'Uyumluluk Sorular',
                    //            translate: 'MENU.APPS.TANIM.UYUMLULUKSORULAR',
                    //            type: 'item',
                    //            icon: 'circle',
                    //            url: 'pages/tanim/uyumluluk-sorular/488A9850-9FD0-43FA-897C-3E70E40B40FC'
                    //        },
                    //        {
                    //            id: 'uyumluluktanim',
                    //            title: 'Uyumluluk Cevaplar',
                    //            translate: 'MENU.APPS.TANIM.UYUMLULUKCEVAPLAR',
                    //            type: 'item',
                    //            icon: 'circle',
                    //            url: 'pages/tanim/uyumluluk-cevaplar/74DD4299-A2FC-4A29-9D98-7763DC9C459F'
                    //        }]
                    //},
                    {
                        id: 'dokumantipleritanim',
                        title: 'Doküman Tipleri',
                        translate: 'MENU.APPS.TANIM.DOKUMANTIPLERI',
                        type: 'item',
                        icon: 'circle',
                        url: 'pages/tanim/dokuman-tipleri-liste/59295F31-C27B-45E3-93F5-4EA13F970BBA'
                    },
                    {
                        id: 'evraktipleritanim',
                        title: 'Evrak Tipleri',
                        translate: 'MENU.APPS.TANIM.EVRAKTIPLERI',
                        type: 'item',
                        icon: 'circle',
                        url: 'pages/tanim/evrak-tipleri-liste/891FE119-57AF-4182-8331-4D5F863326DD'
                    },
                    {
                        id: 'disticaretpaketler',
                        title: 'Paket Listesi',
                        translate: 'Paket Listesi',
                        type: 'item',
                        icon: 'circle',
                        url: 'pages/disticaret/disticaret-paket-liste'
                    },

                ]
            },
            {
                id: 'Hatirlatmalar',
                title: 'Hatirlatmalar',
                translate: 'Hatirlatmalar',
                type: 'item',
                icon: 'log-out',
                url: 'pages/disticaret/disticaret-hatilatma-liste'
            },
            {
                id: 'Logout',
                title: 'Logout',
                translate: 'MENU.APPS.LOGOUT',
                type: 'item',
                icon: 'log-out',
                url: 'authentication/authentication/login'
            },
        ]
    }
];

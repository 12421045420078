export const localeOrtak = {
    lang: 'tr',
    data: {
        Download: "İndir",
        AktifMi:"Aktif / Pasif",
        Listele: "Arama Sonuçları",
        AramaKriterleri: "Arama Kriterleri",
        YeniKayit: "Yeni Kayıt",
        Duzenle: "Düzenle",
        Kaydet: "Kaydet",
        Sil: "Sil",
        Ara: "Ara",
        Temizle: "Temizle",
        Vazgec: "Vazgeç",
        Liste: "Liste",
        LutfenBekleyiniz: "Bekleyiniz",
        Uyari: "Uyarı",
        KayitSilmeOnaymesaji: "Silme işlemi geri alınamaz,  işleme devam etmek istediğinizden emin misiniz?",
        Onayla: "Onayla",
        Bilgi: "Bilgi",
        Durum: "Durum",
        IslemGerceklesti: "İşlem Gerçekleşti.",
        REQ: "Zorunlu Alan",
        TelefonFormat:" Telefon Format +90 (000) 000-00000 şeklinde olmalıdır",
        NO_AUTHENTICATION: "Kimlik doğrulama gerekli",
        OK: "İşlem başarılı",
        FAIL: "Bir sorunla karşılaşıldı",
        SESSION_LOST: "Oturum Sonlandı",
        DATA_ALREADY_EXIST: "Mevcut Veri",
        WRONG_DATA_TYPE: "Yanlış Veri Tipi",
        TOKEN_NOT_VALID: "Geçersiz Anahtar",
        LOGIN: "Giriş",
        NO_AUTHORIZATION: "Yetkisiz İşlem",
        NO_AUTHORIZATION_FOR_THIS_USER: "Bu işlemi yapabilmek için yetkiniz yok",
        NO_VALID_RECENT_PASSWORD: "Eski Şifreniz Geçerli Değil",
        SadeceHarf: "Sadece harf girin",
        SadeceRakam: "Sadece rakam girin",
        GecerliSifre: "En az bir büyük harf, en az bir sayı ve en az 8 karakterden oluşan şifre giriniz",
        Zorunlu: "Zorunlu alan",
        GecerliTarih: "Geçerli tarih girin",
        GecerliTarihFormat: "Tarih ilgili biçime sahip olmalıdır: MM/dd/yyyy",
        GecerliEmail: "Geçerli email girin",
        PasifKayitlar: "Pasik Kayıtları Göster",
        TarihRange: "Bitiş Tarihi başlangıç tarihinden büyük olmalıdır",
        RaporAl: "Rapor",
        Yukle: "Yükle",
        ResimSil: "Sil",
        TumSektorler: "Tüm Sektörler",
        SektorSeciniz: "Sektör Seçiniz",
        Evet: "Evet",
        Hayir: "Hayır",
        CikisMesaji: "Sistemden çıkış yapmak üzeresiniz, işleme devam etmek istiyor musunuz?",
        CikisYap: "Çıkış Yap !",
        OturumUzatmaMesajUst: "Oturumunuzun süresi dolmak üzere!",
        OturumUzatmaMesajAlt: "Aktif kalmaya devam etmek isterseniz, lütfen oturumunuzu uzatmak için 'Devam Et' butonuna tıklayın",
        OturumDevam: "Devam Et",
        OturumCikis: "Kapat",
        OturumMesaj: "Kalan Süre",
        OturumKapatiliyor: "Oturum Kapatılıyor",
        OturumZamanAsimiSayac: " saniye sonra zaman aşımına uğrayacaksınız. Oturumunuz Kapatılacak.",
        NavBarCikis: "Çıkış",
        NavBarSifreDegistir: "Şifre Değiştir",
        LinkOlustur: "Link Oluştur"
    }
}


export const localeMenu = {
    lang: 'tr',
    data: {
        MENU: {
            APPS: {
                SECTION: 'Apps & Pages',
                LOGOUT: 'Çıkış',
                DASHBOARD: 'Ana Sayfa',
                RAPOR: {
                    LIST: 'Raporlar',
                },
                ACCOUNT: {
                    COLLAPSIBLE: 'Account',
                    LIST: 'List',
                    NEW: 'New',
                    ACCESSLIST: 'Access List'
                },
                ORGANIZASYON: {
                    COLLAPSIBLE: 'Firma',
                    LIST: 'Listele',
                    FIRMAIZINLERI:"Firma İzinleri",
                    NEW: 'Yeni Kayıt',
                    ACCESSLIST: 'Access List'
                },
                MUSTERI: {
                    COLLAPSIBLE: 'Müşteri',
                    LIST: 'Listele',
                    NEW: 'Yeni Kayıt',
                },
                PERSONEL: {
                    COLLAPSIBLE: 'Employee',
                    LIST: 'List',
                    NEW: 'New',
                    ACCESSLIST: 'Employee List'
                },
                TANIM: {
                    TANIMLAR: 'Tanımlar',
                    ORGANIZASYON: 'Organizasyon',
                    DOKUMANTIPLERI: "Doküman Tipleri",
                    EVRAKTIPLERI:"Evrak Tipleri"

                },

            }
        }
    }
};





